import { Col, Form, Row, Tooltip } from 'antd';
import React from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';
import { useClaimContext } from 'services/providers/ClaimDeclaration';
import ElectronicDevice from './ElectronicDevice';
import MobileDevice from './MobileDevice';
import Uploader from './Uploader';

function Device() {
  const {
    deviceCategory,
    setSelectedDevice,
    selectedDevice,
    insuredItems,
    hasDeviceModified,
    setHasDeviceModified,
    ...context
  } = useClaimContext();
  const msg = useFormatMsg('claims.declaration.device');
  const checkUpload = () => {
    return context.policyFiles.length > 0
      ? Promise.resolve()
      : Promise.reject(msg('required'));
  };
  const { partner } = useAppState();
  const onModifyDevice = ({ target }) => {
    const field = target.name;
    const selection = selectedDevice[field] || '';
    if (selection !== target.value) {
      setHasDeviceModified(true);
      return;
    }
    setHasDeviceModified(false);
  };
  React.useEffect(() => {
    setSelectedDevice(insuredItems[0]);
  });
  const onChangeDevice = index => {
    const device = insuredItems[index];
    setSelectedDevice(device);
  };

  let uploaderJsx = null;

  if (hasDeviceModified) {
    uploaderJsx = (
      <Row gutter={(16, 16)}>
        <Col xs={24}>
          <Uploader
            name="policyFiles"
            listType="text"
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
      </Row>
    );
  }
  if (['RF', 'BMJP'].includes(partner)) {
    uploaderJsx = (
      <Col xs={24}>
        <Form.Item
          name="policyFiles"
          rules={[
            {
              required: true,
              validator: checkUpload,
            },
          ]}
        >
          <Tooltip title={msg('tooltip_proof_of_purchase')}>
            <Uploader
              name="policyFiles"
              listType="text"
              accept="application/pdf,image/png,image/jpg,image/jpeg"
            />
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  const props = {
    children: uploaderJsx,
    onModifyDevice,
    insuredItems,
    onChangeDevice,
    selectedDevice,
    ...context,
  };

  switch (deviceCategory) {
    case 'MOBILE':
      return <MobileDevice {...props} />;
    case 'ELECTRONICS':
      return <ElectronicDevice {...props} />;
    default:
      return null;
  }
}
export default Device;
